<template>
  <div class="Integral  ">

    <NavHeader />

    <div class="content_container">

      <ul class="list">
        <li v-for='(item,index) in list' :key='index'>
          <div class="cont">
            <div class="l" v-if='item.couponname'>
              <span>
                <p> <i> ￥{{item.money}}</i> </p>
                <p>优惠券 </p>
              </span>

            </div>
            <div class="c">
              <p>{{item.name}}</p>
              <p>市场价值：{{item.money}}</p>

              <p>可用兑换积分：<i> {{item.neednum}}</i>个仪商分</p>

            </div>
            <div class="r" @click="changeReceive(item.id)">
              <i>
                立即领取
              </i>
            </div>
          </div>

        </li>
      </ul>

    </div>
  </div>
</template>

<script type="text/javascript">
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      integral: [],
      total_num: 0,
      total: 0,
      list: [],
      integralTotal: 0,
      where: {
        member_id: this.$store.state.member.id,
        page: 1,
        limit: 10
      }
    };
  },
  filters: {},
  components: {
    NavHeader
  },
  created() { },
  mounted() {
    this.getChange();
  },
  methods: {
    changeReceive(id) {
      this.$post("home/change_receive", { change_id: id, member_id: this.$store.state.member.id, member_name: this.$store.state.member.name }).then(res => {
        this.$message.success('领取成功！')
      });

    },
    getChange() {
      this.$get("admin/change").then(res => {
        this.list = res.items.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.Integral {
  width: 100%;
  background: #fff;
  .list {
    margin: 0 -15px;
    li {
      width: 360px;
      float: left;
      height: 130px;

      box-sizing: border-box;
      border: 1px solid #f2f2f2;
      background: #fff;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
      margin: 15px;
      .cont {
        @include space-between;
        .l {
          float: left;
          width: 100px;
          height: 100px;
          margin: 12px;
          border: 1px solid #f1f2f6;
          @include center;
          color: #999;
          text-align: center;
          i {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: red;
          }
        }
        .c {
          color: #666;
          margin: 15px 0;
          float: left;
          z-index: 2;
          flex: 1;
          height: 118px;

          outline: 0;
          p {
            margin-bottom: 10px;
          }
          p:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
          }
          p:nth-child(2) {
          }
          p:nth-child(3) {
            i {
              font-size: 18px;
              color: red;
            }
          }
        }
      }
      .r {
        width: 42px;
        height: 130px;
        float: right;
        background: url("../../../assets/images/b.jpg") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        i {
          display: block;
          color: #fff;
          width: 16px;
          margin: 0 auto;
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
}

.jfdh {
  float: right;
  margin-top: 30px;
  margin-right: 50px;
}
</style>
